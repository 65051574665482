import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Content from "../components/Content"


const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Content flexCenter={true}>

      <h1>I'm always happy to hear from you.</h1>
      <p>
        📮 mail@nikoschmidt.de
      </p>
      <p>
        <ul>
          <li>
            <a href="https://portfolio.nikoschmidt.de">Portfolio</a>
          </li>
          <li>
            <a target="_blank" href="https://linkedin.com/in/nikolas-leander">LinkedIn</a>
          </li>
        </ul>
      </p>

    </Content>
  </Layout>
)

export default ContactPage
